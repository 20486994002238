import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"]

    handleToggle(e) {
        this.inputTarget.classList.add("hidden")

        if (e.detail.type === "villes[]") {
            this.inputTarget.classList.remove("hidden")
        }
    }
}
