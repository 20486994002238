import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button", "dialog"]

    openDialog() {
        this.dialogTarget.showModal()
    }
    closeDialog() {
        this.dialogTarget.close()
    }
}
